import { createContext, useCallback, useContext, useState } from "react";
import Spinner from "./spinner/Spinner";

const OverlayContext = createContext();

function Roots(props) {
    const [spinner, setSpinner] = useState()

    const hideSpinner = useCallback(() => {
        setSpinner();
    }, [setSpinner]);

    const overlayStatus = {
        spinner: Boolean(spinner),
    };

    return (
        <OverlayContext.Provider
            value={ {
                overlayStatus,
                setSpinner,
            } }
            { ...props }
        >
            { props.children }
            { spinner && <Spinner { ...spinner } hide={ hideSpinner } /> }
        </OverlayContext.Provider>
    );
}

const useOverlay = () => {
    const context = useContext(OverlayContext);

    if (context === undefined) {
        throw new Error("useOverlay must be used within a provider");
    }

    return context;
};

export { Roots, useOverlay };