import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './App.css';
import CPAdsForm from './components/ads-form/AdsForm';
import { useOverlay } from './components/roots';
import { setRoot } from './common/api';
import { clientInfo } from './resources';
import AdsDialog from './components/ads-form/AdsDialog';
import Support from './components/form/Support';
import AboutUs from './components/about-us/AboutUs';

const currentBranch = process.env.REACT_APP_BRANCH;
const isStag = currentBranch === "dev"
  || currentBranch === "stag"
  || currentBranch === "main"
  ? true : false
const isiOS =
  navigator.platform === 'MacIntel' || navigator.platform === 'iPhone';
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
const isWin = navigator.platform.indexOf('Win') > -1

export default function App() {
  const { t } = useTranslation();
  const show = useOverlay()

  const [modal, setModal] = useState(false)
  const [download, setDownload] = useState(false);
  const downloadLink = clientInfo.apk
  const iosLink = clientInfo.ios

  const closeDlFunc = () => {
    setTimeout(() => {
      setDownload(false)
    }, 100)

    var close_dl = document.getElementById("close_dl");
    var hidden_link = document.getElementById('hidden_links')

    hidden_link.style.maxHeight = "0px"
    close_dl.style.opacity = 0;
    close_dl.style.transform = "translate(-50%, 0)";
    close_dl.style.transition = "all 0.30s cubic-bezier(0.165, 0.84, 0.44, 1)";
  }

  const openDlFunc = () => {
    setDownload(true)
    setTimeout(() => {
      var close_dl = document.getElementById("close_dl");
      var hidden_link = document.getElementById('hidden_links')

      hidden_link.style.maxHeight = "200px"
      close_dl.style.opacity = 1;
      close_dl.style.transform = "translate(-50%, -50%)";
      close_dl.style.transition = "all 0.30s cubic-bezier(0.165, 0.84, 0.44, 1)";
    }, 150)
  }

  useEffect(() => {
    setRoot(show)
  }, [show])

  return (
    <div>
      <main>
        <header>
          <img src="img/logo.svg" alt="logo svg" />
          <a className="__show_form_button" onClick={ () => {
            document.getElementById("ads-form").style.display = 'flex';
          } }>{ t('ads_form.title') }</a>
        </header>
        <div className="main_container">
          <div className="top">
            <img src="img/dude.png" className="dude" alt='dude' />
            <div className="left_block">
              <h1>{ t('general.desc') } <u>{ t('general.desc2') }</u> { t('general.desc3') }</h1>
              <div className="left_block_lower">
                <div className="download_btn __show_dl_button" onClick={ () => {
                  if (isStag) {
                    setModal(true)
                  } else {
                    window.open(
                      isiOS || isMac ? iosLink : isWin ? downloadLink : downloadLink,
                      "_blank")
                  }
                } }>
                  <span>
                    <img
                      src={ `${isiOS || isMac ? 'img/apple.svg'
                        : isWin ? 'img/android.svg' : 'img/android.svg'}` }
                      alt={ `${isiOS || isMac ? 'apple svg'
                        : isWin ? 'android svg' : 'android svg'}` } />
                    { t('general.download') }
                  </span>
                </div>
                {/* <span className="android_only">
                  <img src="img/ex.svg" alt='ex svg' />
                  { t('general.availableDevice') }
                </span> */}
              </div>
            </div>
          </div>
        </div>
        <AboutUs />
        <Support />
        <footer style={ { justifyContent: 'center' } }>
          <img src="img/logo_only.svg" alt='logo_only' />
          { t('general.copyRight') }{ '   ' }{ clientInfo.ver }
        </footer>
      </main>
      <AdsDialog />
      <CPAdsForm />
      { modal &&
        <div className="pop_download showed">
          <div className="inner_pop_download">
            <div className="pop_download_top">
              <h2>{ t('step.title') }</h2>
              <span className="close_download"
                onClick={ () => {
                  closeDlFunc()
                  setModal(false)
                } }>
                <img src="img/x.svg" alt="closeModal" />
              </span>
            </div>
            <div className="steps">
              <div className="each_step">
                <div className="step_img">
                  <img src="img/step_01.jpg" alt="step1" />
                </div>
                <div className="step_des">
                  <small>{ t("step.step_1") }</small>
                  <span>{ t('step.step_1_desc') }</span>
                </div>
              </div>
              <div className="each_step __dl_links_trigger">
                <div className="step_img">
                  <img src="img/step_02.jpg" alt="step2" />
                </div>
                <div className="step_des" onClick={ () => {
                  if (download) {
                    closeDlFunc()
                  } else {
                    openDlFunc()
                  }
                } }>
                  <small>{ t("step.step_2") }</small>
                  <span>
                    <div className='step2btn'>{ t('step.step_2_btn') }</div>
                    { t('step.step_2_desc') }
                  </span>
                </div>
              </div>
              <div id="hidden_links" className={ `hidden_download_links ${download ? 'hidden_dl_links_open' : 'hidden_dl_links_close'}` }>
                <div className='inner_hidden_download_links'>
                  <strong>{ t("step.step2_dl_collapse.title") }</strong>
                  <span id="close_dl" className={ `close_dl_links __close_dl_links` }
                    onClick={ () => {
                      if (download) {
                        closeDlFunc()
                      }
                    } }>
                    <img src="img/close_dl.svg" alt="closeDl" />
                  </span>
                  <div onClick={ () => {
                    window.open(
                      downloadLink,
                      "_blank"
                    )
                  } }>
                    <span className="dl_des">{ t("step.step2_dl_collapse.dl_1") }</span>
                    <span className="dl_icon">
                      <img src="img/dl.svg" alt="expand" />
                      <img src="img/dl_f.svg" className="dl_f" alt="expand2" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="each_step">
                <div className="step_img">
                  <img src="img/step_03.jpg" alt="step3" />
                </div>
                <div className="step_des">
                  <small>{ t("step.step_3") }</small>
                  <span dangerouslySetInnerHTML={ { __html: t('step.step_3_desc') } } />
                </div>
              </div>
              <div className="each_step">
                <div className="step_img">
                  <img src="img/step_04.jpg" alt="step4" />
                </div>
                <div className="step_des">
                  <small dangerouslySetInnerHTML={ { __html: t('step.step_4') } } />
                  <span>{ t("step.step_4_desc") }</span>
                </div>
              </div>
              <div className="each_step __multi">
                <div className="step_img">
                  <img src="img/step_05.jpg" alt="step5" />
                </div>
                <div className="step_des">
                  <small dangerouslySetInnerHTML={ { __html: t('step.step_5') } } />
                  <span dangerouslySetInnerHTML={ { __html: t('step.step_5_desc') } } />
                </div>
                <div className="my_files">
                  <span dangerouslySetInnerHTML={ { __html: t('step.step_5_desc_1') } } />
                  <div className="right"><img src="img/step_05b.jpg" alt="step5b" /></div>
                </div>
              </div>
              <div className="each_step">
                <div className="step_img">
                  <img src="img/step_06.jpg" alt="step6" />
                </div>
                <div className="step_des">
                  <small>{ t('step.step_6') }</small>
                  <span dangerouslySetInnerHTML={ { __html: t('step.step_6_desc') } } />
                </div>
              </div>
              <div className="each_step">
                <div className="step_img">
                  <img src="img/step_07.jpg" alt="step7" />
                </div>
                <div className="step_des">
                  <small>{ t('step.step_7') }</small>
                  <span> { t('step.step_7_desc') } </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div >
  );
}