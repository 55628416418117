import { clientInfo } from "../resources";

export default {
  general: {
    desc: "ฟรี",
    desc2: "กีฬาสดสตรีมมิ่ง",
    desc3: "บนโทรศัพท์ของคุณ!",
    download: "ดาวน์โหลดแอป",
    availableDevice: "มีเฉพาะบน Android เท่านั้น",
    copyRight: `© ${clientInfo.brand} สงวนลิขสิทธิ์.`,
    downloadTitle: "ดาวน์โหลดไฟล์ APK",
    downloadText: "ลิ้งค์ดาวน์โหลด"
  },
  step: {
    title: "วิธีดาวน์โหลดและติดตั้ง",
    step_1: "ขั้นตอนที่ 1",
    step_1_desc: ` ถอนการติดตั้ง ${clientInfo.brand} เวอร์ชันเก่าหากคุณยังมีอยู่ในโทรศัพท์ของคุณ`,
    step_2: "ขั้นตอนที่ 2",
    step_2_btn: "ดาวน์โหลด",
    step_2_desc: ` ไฟล์ ${clientInfo.brand} APK ใหม่`,
    step2_dl_collapse: {
      title: "ลิงค์ดาวน์โหลดไฟล์ APK",
      dl_1: "ลิ้งค์ดาวน์โหลด"
    },
    step_3: "ขั้นตอนที่ 3",
    step_3_desc: "แตะ <b>\"ดาวน์โหลดต่อไป\"</b> เพื่อดำเนินการต่อ",
    step_4: "ขั้นตอนที่ 4 <b>สถานการณ์</b>",
    step_4_desc: "เปลี่ยนการตั้งค่าความปลอดภัยเพื่อให้โทรศัพท์ของคุณติดตั้งแอปที่ดาวน์โหลดจากแหล่งอื่น",
    step_5: "ขั้นตอนที่ 5 <b>สถานการณ์</b>",
    step_5_desc: "หากคุณใช้ Chrome เป็นเบราว์เซอร์เริ่มต้น ให้เปิดปุ่มสลับ <b>\"Chrome\"</b> เพื่อดำเนินการต่อ",
    step_5_desc_1: "หากคุณดาวน์โหลด APK ไว้แล้ว ให้เปิดปุ่มสลับ <b>\"ไฟล์ของฉัน\"</b> แทน",
    step_6: "ขั้นตอนที่ 6",
    step_6_desc: ` แตะ <b>\"ติดตั้ง\"</b> เพื่อเริ่มการติดตั้งแอป ${clientInfo.brand} ใหม่`,
    step_7: "ขั้นตอนที่ 7",
    step_7_desc: `คุณพร้อมแล้ว! เปิดแอป ${clientInfo.brand} แล้วสนุกได้เลย!`
  },
  ads_form: {
    boost: {
      title: 'เมื่อไหร่ที่ควรเพิ่ม'
    },
    dialog: {
      title: "ขอบคุณ",
      Desc: "เราจะติดต่อกลับโดยเร็วที่สุด",
      btn: "ตกลง"
    },
    title: "ลงโฆษณากับเรา",
    required: "ต้องระบุ",
    invalid: "รูปแบบไม่ถูกต้อง",
    name: "ชื่อ",
    email: "อีเมล",
    contact: "ติดต่อ",
    product: "สินค้าที่จะโฆษณา",
    select: "โปรดเลือก 1 หรือหลายรายการ",
    type: {
      fashion: "แฟชั่น",
      car: "ตัวแทนจำหน่ายรถยนต์",
      flower: "ร้านดอกไม้",
      accessory: "อุปกรณ์เสริม",
      other: "อื่นๆ"
    },
    desc: "อธิบายผลิตภัณฑ์ของคุณ",
    remark: "ข้อสังเกต",
    require: "ช่องที่ต้องกรอก",
    submit: "ส่ง",
    backToApp: "กลับไปที่ APP"
  },
  support: {
    title: 'ต้องการความช่วยเหลือไหม?',
    desc: 'ทีมสนับสนุนลูกค้าของเราพร้อมให้ความช่วยเหลือ!',
    desc2: `เราจะตอบกลับคุณหลังจากฝากข้อความไว้`,
    name: 'ชื่อ',
    email: 'อีเมล',
    issue: 'คำถาม',
    options: {
      feedback: 'ข้อเสนอแนะ',
      request: 'คุณสมบัติใหม่',
      bug: 'ข้อบกพร่องของแอป',
      others: 'อื่นๆ'
    },
    details: 'รายละเอียด',
    submit: "ส่ง",
  },
  aboutUs: {
    aboutUs: {
      title: 'เกี่ยวกับเรา',
      desc: `ยินดีต้อนรับสู่ {{boldText}} ที่สุดของแพลตฟอร์มสำหรับการสตรีมสดและการเรียนรู้เกี่ยวกับโลกของอีสปอร์ต ไม่ว่าคุณจะหลงใหลในอีฟุตบอล อีบาสเกตบอล หรือการแข่งขันดิจิทัลรูปแบบอื่น ๆ {{boldText}} คือแพลตฟอร์มที่เหมาะสำหรับคุณในการเข้าถึงเนื้อหาชั้นนำและการพัฒนาทักษะที่ไม่มีใครเทียบได้`
    },
    platform: {
      title: 'แพลตฟอร์มของเรา',
      desc: `{{boldText}} ถูกออกแบบมาเพื่อตอบสนองความต้องการที่เพิ่มขึ้นสำหรับเนื้อหาอีสปอร์ตคุณภาพสูง ด้วยการเน้นที่อีฟุตบอล อีบาสเกตบอล และอีสปอร์ตทั่วไป แพลตฟอร์มของเรามีฟีเจอร์ที่ครบถ้วน รวมถึง:`,
      point1: '{{boldText}}: ชมการแข่งขันแบบเรียลไทม์จากผู้เล่นและทีมชั้นนำทั่วโลก การสตรีมความละเอียดสูงของเราจะทำให้คุณไม่พลาดทุกช่วงเวลาที่สำคัญ',
      point2: `{{boldText}}: เรียนรู้จากสุดยอดผู้เล่นด้วยบทเรียนเฉพาะทาง การวิเคราะห์กลยุทธ์ และเคล็ดลับระดับโปร เนื้อหาของเราได้รับการออกแบบเพื่อช่วยให้ผู้ใช้พัฒนาทักษะ ไม่ว่าจะเป็นมือใหม่หรือผู้เล่นที่มีประสบการณ์แล้ว`,
      // point3: `{{boldText}}: เข้าร่วมชุมชนเกมเมอร์ระดับโลกที่มีความหลงใหลในสิ่งเดียวกัน มีส่วนร่วมในแชทสดระหว่างการสตรีม เข้าร่วมฟอรัม และเชื่อมต่อกับแฟนๆ และผู้เล่นคนอื่น ๆ`
    },
    why: {
      title: 'ทำไมต้องเลือก VECTORSPORT?',
      desc: `ไม่ว่าคุณจะเป็นผู้เล่นเพื่อความสนุกหรือมีความมุ่งมั่นที่จะเป็นมืออาชีพ {{boldText}} มีบางอย่างสำหรับทุกคน ลงทะเบียนวันนี้เพื่อเริ่มสตรีม การเรียนรู้ และเชื่อมต่อกับชุมชนอีสปอร์ตทั่วโลก มาร่วมกันพัฒนาทักษะการเล่นเกมของคุณไปสู่ระดับใหม่`
    },
    future: {
      title: "แผนการในอนาคต",
      desc: `เรารู้สึกตื่นเต้นที่จะประกาศว่า {{boldText}} กำลังขยายขอบเขตไปสู่สิ่งใหม่ ๆ! นอกจากอีฟุตบอลแล้ว เราจะพัฒนาคอนเทนต์สำหรับอีบาสเก็ตบอลและเกมอีสปอร์ตยอดนิยมอื่น ๆ ในเร็ว ๆ นี้ รอติดตามคุณสมบัติใหม่ ๆ และการอัปเดตที่จะช่วยเพิ่มประสบการณ์การเล่นเกมของคุณ และเปิดโอกาสในการพัฒนาทักษะเพิ่มเติม`
    },
    promote: {
      title: "โปรโมตสโมสรของคุณ",
      desc: `หากคุณเป็นส่วนหนึ่งของสโมสรกีฬาและต้องการโปรโมตกิจกรรมของคุณ {{boldText}} มอบแพลตฟอร์มที่ยอดเยี่ยมในการแสดงทีมและกิจกรรมของคุณ ติดต่อเราเพื่อสำรวจโอกาสในการร่วมมือกันและเพิ่มการมองเห็นสโมสรของคุณในชุมชนอีสปอร์ต`
    }
  },
  err: {
    "timeout": "คำขอหมดเวลา"
  }
}
