import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './language/en';
import zh from './language/zh';
import th from './language/th';

const defaultLang = navigator.language.split('-')[0];

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            zh: {
                translation: zh,
            },
            th: {
                translation: th,
            },
        },
        lng: defaultLang,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
