import React from 'react'
import { useTranslation } from 'react-i18next';

import "./adsDialog.css"
import "../../App.css"
import { clientInfo } from '../../resources';

export default function AdsDialog() {
    const { t } = useTranslation();
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);

    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const urlObj = new URL(window.location.href);
    const appBtn = urlObj.searchParams.has('app') && (params.get('app') === "1")

    const close = () => {
        document.getElementById("ads-dialog").style.display = 'none';
    }

    return (
        <div id='ads-dialog' className='pop_thank_you'>
            <div className="inner_pop_thank_you">
                <div className="thank_you_message">
                    <span className="close_thank_you" onClick={ () => close() }>
                        <img src="img/x.svg" />
                    </span>
                    <h1>{ t('ads_form.dialog.title') }</h1>
                    <p>{ t('ads_form.dialog.desc') }</p>
                    <a className="close_thank_you" onClick={ () => {
                        close()
                        if (appBtn && isMobile) {
                            window.open(clientInfo.intent, '_blank');
                        } else {
                            window.location.replace(window.location.href);
                        }
                    } }>{ t('ads_form.dialog.btn') }</a>
                </div>
            </div>
        </div>
    )
}