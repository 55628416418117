import { clientInfo } from "../resources";

export default {
  general: {
    desc: "Free",
    desc2: "Sports Live Streaming",
    desc3: "on Your Phone !",
    download: "DOWNLOAD APP",
    availableDevice: "Available on android only",
    copyRight: `© ${clientInfo.brand}. All rights reserved.`,
    downloadTitle: "Download APK",
    downloadText: "Download link"
  },
  step: {
    title: "How to download and install",
    step_1: "STEP 1",
    step_1_desc: ` Uninstall the older version ${clientInfo.brand} if you still have it on your phone`,
    step_2: "STEP 2",
    step_2_btn: "Download",
    step_2_desc: ` New ${clientInfo.brand} APK file`,
    step2_dl_collapse: {
      title: "APK download links",
      dl_1: "Download link"
    },
    step_3: "STEP 3",
    step_3_desc: "Tap <b>\"Download anyway\"</b> to continue",
    step_4: "STEP 4 <b>Situational</b>",
    step_4_desc: "Change the security settings to allow your phone to install apps downloaded from other sources",
    step_5: "STEP 5 <b>Situational</b>",
    step_5_desc: "If you're using Chrome as your default browser, then turn on the <b>\"Chrome\"</b> toggle button to continue",
    step_5_desc_1: "If you already have the APK downloaded, then turn on the <b>\"My files\"</b> toggle button instead",
    step_6: "STEP 6",
    step_6_desc: ` Tap <b>\"Install\"</b> to begin installing the new ${clientInfo.brand} app`,
    step_7: "STEP 7",
    step_7_desc: `You are all set! Open ${clientInfo.brand} app and enjoy!`
  },
  ads_form: {
    dialog: {
      title: "Thank You",
      desc: "We will get back to you as soon as possible",
      btn: "OK"
    },
    boost: {
      title: 'When to Boost?'
    },
    title: "Advertise with us",
    required: "Required",
    invalid: "Invalid Format",
    name: "Name",
    email: "Email",
    contact: "Contact",
    product: "Product to Advertise",
    select: "Please select 1 or multiple",
    type: {
      fashion: "Fashion",
      car: "Car dealer",
      flower: "Flower Shop",
      accessory: "Accessory",
      other: "Other"
    },
    desc: "Describe your product",
    remark: "Remark",
    require: "Required field",
    submit: "SUBMIT",
    backToApp: "BACK TO APP"
  },
  support: {
    title: 'Need help?',
    desc: 'Our customer support team is always here to help!',
    desc2: `Leave a message & we'll get back to you.`,
    name: 'Name',
    email: 'Email',
    issue: 'Issue',
    options: {
      feedback: 'Feedback',
      request: 'Feature Request',
      bug: 'Bug',
      others: 'Others'
    },
    details: 'Details',
    submit: "SUBMIT",
  },
  aboutUs: {
    aboutUs: {
      title: 'About Us',
      desc: `Welcome to {{boldText}}, the ultimate destination for live-streaming and mastering the world of e-sports. Whether you're passionate about e-football, {{boldText}} is your go-to platform for top-tier content and unparalleled skill development.`
    },
    platform: {
      title: 'Our Platform',
      desc: `{{boldText}} was designed to meet the growing demand for high-quality e-sports content. With a focus on e-football our platform offers a comprehensive suite of features, including:`,
      point1: '{{boldText}}: Watch real-time matches featuring top players and teams from around the world. Our high-definition streams ensure you never miss a moment of the action.',
      point2: `{{boldText}}: Learn from the best with our exclusive tutorials, strategy breakdowns, and pro tips. Our content is curated to help users improve their skills, whether they're beginners or seasoned pros.`,
      // point3: `Interactive Community: Join a global community of gamers who share your passion. Engage in live chat during streams, participate in forums, and connect with fellow fans and players.`
    },
    why: {
      title: 'Why Choose VECTORSPORT?',
      desc: `Whether you're a casual fan or an aspiring pro, {{boldText}} offers something for everyone. Sign up today to start streaming, learning, and connecting with the global e-sports community. Together, we'll take your game to the next level.`
    },
    future: {
      title: "Future Plans",
      desc: `We're excited to announce that {{boldText}} is expanding its horizons! In addition to e-football, we will soon be developing content for e-basketball and other popular e-sports games. Stay tuned for new features and updates that will enhance your gaming experience and provide even more opportunities for skill development`
    },
    promote: {
      title: 'Promote Your Club',
      desc: `If you're part of a sports club and want to promote your activities, {{boldText}} offers a fantastic platform to showcase your team and events.Reach out to us to explore collaboration opportunities and increase your club's visibility within the e-sports community`
    }
  },
  err: {
    timeout: "The request timed out"
  }
}