import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../../App.css'
import { validateEmail } from '../../common/utils/format';
import api from '../../common/api';
import { clientInfo } from '../../resources';

// const Label = React.memo(({ type, onChange, requireComponent }) => {
//     const [error, setError] = useState(false);

//     const handleInputChange = useCallback((e) => {
//         const newName = e.target.value;
//         onChange(newName);
//         setError(!newName); // Show error if newName is empty
//     }, [onChange]);

//     return (
//         <div className="each_fieldset">
//             <span className="label">Name<b>*</b></span>
//             <div className="input_wrap">
//                 <input
//                     value={ type }
//                     type="text"
//                     placeholder="John Doe"
//                     onChange={ handleInputChange }
//                 />
//                 { requireComponent }
//                 {/* { error && <span className="error_msg">Required</span> } */ }

//             </div>
//         </div>
//     );
// });

export default function CPAdsForm() {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const openAdsForm = params.get("page");
    const urlObj = new URL(window.location.href);
    const appBtn = urlObj.searchParams.has('app') && (params.get('app') === "1")
    const boost7to10 = urlObj.searchParams.has('app') && (params.get('boost') === "7to10")
    const boost10to12 = urlObj.searchParams.has('app') && (params.get('boost') === "10to12")
    const boostNormal = urlObj.searchParams.has('app') && (params.get('boost') === "normal")
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);

    const { t } = useTranslation();
    const [isOthers, setIsOthers] = useState(false); // tick others option
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [remark, setRemark] = useState('')
    const [others, setOthers] = useState('') // others description
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [checkboxes, setCheckboxes] = useState([
        { id: 'item_01', label: "fashion", checked: false },
        { id: 'item_02', label: "car", checked: false },
        { id: 'item_03', label: "flower", checked: false },
        { id: 'item_04', label: "accessory", checked: false },
    ]);
    const [social, setSocial] = useState('Telegram');
    const [shake, setShake] = useState(false)
    const [boost, setBoost] = useState([
        { id: '1', label: "7pm - 10pm", checked: boost7to10 ? true : false },
        { id: '2', label: "10pm - 12pm", checked: boost10to12 ? true : false },
        { id: '3', label: "Normal hours", checked: boostNormal ? true : false },
    ]);

    const products = checkboxes.filter(item => item.checked);

    useEffect(() => {
        if (openAdsForm === 'ads-form') {
            document.getElementById("ads-form").style.display = 'flex';
            return
        }

        document.getElementById("ads-form").style.display = 'none';
    }, [openAdsForm]);

    const removeParams = () => {
        const queryString = window.location.href;
        const url = new URL(queryString);
        url.searchParams.delete('page');
        window.history.replaceState({}, document.title, url.toString());
    }

    const closeModal = () => {
        setShake(false)
        setName('')
        setEmail('')
        setContact('')
        setRemark('')
        setOthers('')
        setIsOthers(false)
        setIsValidEmail(false)
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) => ({ ...checkbox, checked: false }))
        );
        setBoost((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) => ({ ...checkbox, checked: false }))
        );

        document.getElementById("ads-form").style.display = 'none';
        removeParams()
    }

    const handleSubmit = async () => {
        const product = checkboxes.filter((i) => i.checked === true)
        const getProducts = product.map(item => item.label);
        const boostItem = boost.find(item => item.checked === true);

        if (
            !name ||
            !email ||
            !isValidEmail ||
            !contact ||
            ((product.length <= 0 && !isOthers) || (isOthers && !others)) ||
            !boostItem
        ) {
            setShake(true)
            setTimeout(() => {
                setShake(false)
            }, 300)
            return;
        }

        const data = {
            name,
            email,
            contact: {
                social: social,
                id: contact
            },
            product: getProducts || [],
            others,
            boost: boostItem.label,
            remark
        }

        const res = await api.adsForm(data);

        if (res.status === 'Ok') {
            document.getElementById("ads-form").style.display = 'none';
            document.getElementById("ads-dialog").style.display = 'flex';
            closeModal()
        }
    };

    const handleCheckboxChange = (id) => {
        setCheckboxes((prevCheckboxes) =>
            prevCheckboxes.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
            )
        );
    };

    const handleBoostChange = (id) => {
        setBoost((prevBoost) =>
            prevBoost.map((item) =>
                item.id === id
                    ? { ...item, checked: true }
                    : { ...item, checked: false }
            )
        );
    };
    return (
        <div className="pop_form showed" id='ads-form' onClick={ (e) => {
            if (e.target.id === 'ads-form') {
                closeModal()
            }
        } }>
            <div className={ `inner_pop_form ${shake ? 'shake' : null}` }>
                <div className="pop_download_top">
                    <h2>{ t("ads_form.title") }</h2>
                    <span className="close_download" onClick={ () => {
                        closeModal()
                    } }>
                        <img src="img/x.svg" alt="closeModal" />
                    </span>
                </div>
                <div className="form">
                    <div className="each_fieldset">
                        <span className="label">{ t('ads_form.name') }{ !name && <b>*</b> }</span>
                        <div className="input_wrap">
                            <input
                                value={ name }
                                type="text"
                                placeholder="John Doe"
                                onChange={ (e) => {
                                    setName(e.target.value);
                                } } />
                            { !name && <span className="error_msg">{ t('ads_form.required') }</span> }
                        </div>
                    </div>
                    <div className="each_fieldset">
                        <span className="label">{ t('ads_form.email') }{ !isValidEmail && <b>*</b> }</span>
                        <div className="input_wrap">
                            <input
                                value={ email }
                                type="text" placeholder="John_doe@email.com"
                                onChange={ (e) => {
                                    const newEmail = e.target.value;
                                    setEmail(newEmail);
                                    setIsValidEmail(validateEmail(newEmail));
                                } } />
                            { (!isValidEmail && email) && <span className="error_msg">{ t('ads_form.invalid') }</span> }
                            { !email && <span className="error_msg">{ t('ads_form.required') }</span> }
                        </div>
                    </div>
                    <div className="each_fieldset">
                        <span className="label">{ t('ads_form.contact') }{ !contact && <b>*</b> }</span>
                        <div className="input_wrap __is_contact">
                            <select value={ social } onChange={ (e) => setSocial(e.target.value) }>
                                <option>Telegram</option>
                                <option>WhatsApp</option>
                                <option>WeChat</option>
                                <option>Line</option>
                            </select>
                            <input
                                value={ contact }
                                type="text"
                                onChange={ (e) => {
                                    setContact(e.target.value);
                                } }
                            />
                            { !contact && <span className="error_msg">{ t('ads_form.required') }</span> }
                        </div>
                    </div>
                    <div className="each_fieldset __is_multiple" style={ { borderBottom: 'none' } }>
                        <span className="label">
                            { t('ads_form.product') }{ !(products.length > 0 || isOthers) && <b>*</b> }
                            <small>{ t('ads_form.select') }</small>
                            { !(products.length > 0 || isOthers) && <span className="error_msg">{ t('ads_form.required') }</span> }
                        </span>
                        <div className="multiple_input_wrap">
                            { checkboxes.map((checkbox) => (
                                <div className="each_multiple_box" key={ checkbox.id }>
                                    <input
                                        type="checkbox"
                                        id={ checkbox.id }
                                        checked={ checkbox.checked }
                                        onChange={ () => handleCheckboxChange(checkbox.id) }
                                    />
                                    <label htmlFor={ checkbox.id }>{ t(`ads_form.type.${checkbox.label.toLowerCase()}`) }</label>
                                </div>
                            )) }
                            <div className="each_multiple_box">
                                <input
                                    type="checkbox"
                                    id="item_other"
                                    checked={ isOthers }
                                    onChange={ () => {
                                        if (!isOthers) {
                                            setOthers('')
                                        }
                                        setIsOthers(!isOthers);
                                    } } />
                                <label htmlFor="item_other">{ t('ads_form.type.other') }{ (isOthers && !others) && <b> *</b> }</label>
                                { (isOthers) && (
                                    <div className="expanded_textbox">
                                        <textarea
                                            style={ { minWidth: 200, paddingLeft: 10, minHeight: 50 } }
                                            value={ others }
                                            placeholder={ t('ads_form.desc') }
                                            onChange={ (e) => {
                                                setOthers(e.target.value)
                                            } }></textarea>
                                        { !others && <span className="error_msg_2">{ t('ads_form.required') }</span> }
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                    <div className="each_fieldset __is_multiple">
                        <span className="label">
                            { t('ads_form.boost.title') }{ !(products.length > 0 || isOthers) && <b>*</b> }
                            { !(products.length > 0 || isOthers) && <span className="error_msg">{ t('ads_form.required') }</span> }
                        </span>
                        <div className="multiple_input_wrap">
                            { boost.map((item) => (
                                <div className="each_multiple_box" key={ item.id }>
                                    <input
                                        type="checkbox"
                                        id={ item.id }
                                        checked={ item.checked }
                                        onChange={ () => handleBoostChange(item.id) }
                                    />
                                    <label htmlFor={ item.id }>{ item.label }</label>
                                </div>
                            )) }
                        </div>
                    </div>
                    <div className="each_fieldset __is_remark">
                        <span className="label">{ t('ads_form.remark') }</span>
                        <div className="input_wrap">
                            <textarea value={ remark } onChange={ (e) => {
                                setRemark(e.target.value)
                            } }></textarea>
                        </div>
                    </div>
                </div>
                <div className="submit_btn_wrap">
                    { (appBtn && isMobile) && <button type="button" onClick={ () => {
                        window.open(clientInfo.intent, '_blank');
                    } }>
                        { t('ads_form.backToApp') }</button> }
                    <small><b>*</b> { t('ads_form.require') }</small>
                    <button type="submit" onClick={ handleSubmit }> { t('ads_form.submit') }</button>
                </div>
            </div>
        </div>
    )
}