import { clientInfo } from "../resources";

export default {
  general: {
    desc: "免费",
    desc2: "体育直播",
    desc3: "就在你手机上！",
    download: "下载应用",
    availableDevice: "只限安卓手机",
    copyRight: `© ${clientInfo.brand}. All rights reserved.`,
    downloadTitle: "下载APK",
    downloadText: "下载链接"
  },
  step: {
    title: "如何下载安装",
    step_1: "步骤 1",
    step_1_desc: ` 如果${clientInfo.brand}已经安装在您的手机里, 需要卸载`,
    step_2: "步骤 2",
    step_2_btn: "下载",
    step_2_desc: ` 新${clientInfo.brand} APK文件`,
    step2_dl_collapse: {
      title: "APK下载链接",
      dl_1: "下载链接"
    },
    step_3: "步骤 3",
    step_3_desc: "点击 <b>\"下载\"</b>继续使用应用",
    step_4: "步骤 4 <b>情境</b>",
    step_4_desc: "更改安全设置以允许您的手机安装从其他来源下载的应用程序",
    step_5: "步骤 5 <b>情境</b>",
    step_5_desc: "如果您使用 Chrome 作为默认浏览器，请打开<b>\"Chrome\"</b>切换按钮继续",
    step_5_desc_1: "如果您已经下载了 APK,请打开<b>\"我的文件\"</b>切换按钮",
    step_6: "步骤 6",
    step_6_desc: `点按<b>\"安装\"</b>开始安装新的 ${clientInfo.brand} 应用程序`,
    step_7: "步骤 7",
    step_7_desc: `你已经准备好了！打开 ${clientInfo.brand} 应用程序并享受吧！`
  },
  ads_form: {
    boost: {
      title: '广告投放时间'
    },
    dialog: {
      title: "谢谢",
      Desc: "我们会尽快联络您",
      btn: "好的"
    },
    title: "广告合作",
    required: "必填项",
    invalid: "各式错误",
    name: "名字",
    email: "邮件",
    contact: "联络号码",
    product: "广告产品",
    select: "请选择1个或多个",
    type: {
      fashion: "时装",
      car: "汽车经销商",
      flower: "花店",
      accessory: "配饰",
      other: "其他"
    },
    desc: "描述产品",
    remark: "注明",
    require: "必填项目",
    submit: "提交",
    backToApp: "回去APP"
  },
  support: {
    title: '咨询更多？',
    desc: '我们的客户支持团队随时为您提供帮助！',
    desc2: `留言后我们将回复您。`,
    name: '名字',
    email: '邮件',
    issue: '问题',
    options: {
      feedback: '反馈',
      request: '功能请求',
      bug: '漏洞',
      others: '其他的'
    },
    details: '详情信息',
    submit: "提交",
  },
  aboutUs: {
    aboutUs: {
      title: '关于我们',
      desc: `欢迎来到{{boldText}}，这是直播和掌握电竞世界的终极目的地。无论你是对电子足球、电子篮球还是其他竞技数字领域充满热情，{{boldText}}都是你获取顶级内容和无与伦比技能发展的首选平台。`
    },
    platform: {
      title: '我们的平台',
      desc: `{{boldText}}旨在满足对高质量电竞内容日益增长的需求。我们专注于电子足球、电子篮球和一般电竞，并提供一套全面的功能，包括：`,
      point1: '{{boldText}}：观看来自世界各地顶级选手和团队的实时比赛。我们提供高清流媒体，确保你不会错过任何一个精彩瞬间。',
      point2: `{{boldText}}：通过我们的独家教程、策略分析和专业技巧，向最佳选手学习。我们的内容经过精心策划，旨在帮助用户提高技能，无论他们是初学者还是经验丰富的玩家。`,
      // point3: `互动社区：加入全球电竞爱好者社区，与同样热爱电竞的玩家互动。在直播期间参与实时聊天，参加论坛讨论，并与其他粉丝和玩家建立联系。`
    },
    why: {
      title: '为什么选择VECTORSPORT？',
      desc: `无论你是休闲玩家还是有志成为职业选手，{{boldText}}都能为每个人提供合适的内容。今天就注册，开始流媒体观看、学习并与全球电竞社区建立联系。让我们一起将你的游戏水平提升到新的高度。`
    },
    future: {
      title: "未来计划",
      desc: `我们很高兴地宣布，{{boldText}} 正在拓展其视野！除了电子足球，我们很快还会开发电子篮球及其他热门电子竞技游戏的内容。敬请期待新功能和更新，这些将增强您的游戏体验，并提供更多的技能发展机会。`
    },
    promote: {
      title: "推广您的俱乐部",
      desc: `如果您是体育俱乐部的一员，并希望推广您的活动，{{boldText}} 提供了一个绝佳的平台来展示您的团队和活动。联系我们，探索合作机会，并提升您俱乐部在电子竞技社区中的知名度。`
    }
  },
  err: {
    timeout: "请求超时"
  }
}
