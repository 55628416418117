import React from "react"
import "./aboutUs.css"
import { useTranslation } from "react-i18next"

export default function AboutUs() {
    const { t } = useTranslation()
    const abtUs = t('aboutUs.aboutUs.desc', { boldText: '<strong>VECTORSPORT</strong>' });
    const platform = t('aboutUs.platform.desc', { boldText: '<strong>VECTORSPORT</strong>' });
    const why = t('aboutUs.why.desc', { boldText: '<strong>VECTORSPORT</strong>' });
    const pont1 = t('aboutUs.platform.point1', { boldText: '<strong>Live Streaming</strong>' });
    const pont2 = t('aboutUs.platform.point2', { boldText: '<strong>Skill Development</strong>' });
    const future = t('aboutUs.future.desc', { boldText: '<strong>VECTORSPORT</strong>' });
    const promote = t('aboutUs.promote.desc', { boldText: '<strong>VECTORSPORT</strong>' });

    return (
        <div className="about_us_main_container">
            <div className='about_us_container'>
                <h1>{ t('aboutUs.aboutUs.title') }</h1>
                <p dangerouslySetInnerHTML={ { __html: abtUs } } />
                <h1>{ t('aboutUs.platform.title') }</h1>
                <p style={ { textAlign: 'left' } } dangerouslySetInnerHTML={ { __html: platform } } />
                <ul>
                    <li dangerouslySetInnerHTML={ { __html: pont1 } } />
                    <br />
                    <li dangerouslySetInnerHTML={ { __html: pont2 } } />
                </ul>
                <h1>{ t('aboutUs.future.title') }</h1>
                <p dangerouslySetInnerHTML={ { __html: future } } />
                <h1>{ t('aboutUs.promote.title') }</h1>
                <p dangerouslySetInnerHTML={ { __html: promote } } />
                <h2>{ t('aboutUs.why.title') }</h2>
                <p dangerouslySetInnerHTML={ { __html: why } } />
            </div>
        </div>
    )
}