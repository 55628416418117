import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./support.css"
import { validateEmail } from '../../common/utils/format';
import api from '../../common/api';

export default function Support() {
    const { t } = useTranslation();
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [issue, setIssue] = useState(t('support.options.feedback'))
    const [detail, setDetail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [shake, setShake] = useState(false)

    const onPress = async () => {
        if (
            !name ||
            !email ||
            !isValidEmail ||
            !detail
        ) {
            setShake(true)
            setTimeout(() => {
                setShake(false)
            }, 300)
            return;
        }

        const data = { name, email, issue, detail }
        const res = await api.supportForm(data);

        if (res.status === 'Ok') {
            document.getElementById("ads-dialog").style.display = 'flex';
        }


    }

    return (
        <div className={ `support_main_container ${shake ? 'shake' : null}` } >
            <div className='support_container'>
                <div className='support_desc_container'>
                    <h1>{ t('support.title') }</h1>
                    <p>{ t('support.desc') }<br />{ t('support.desc2') }</p>
                </div>
                <div className='support_input_container'>
                    <div className="input_container">
                        <label className="input_lable">{ t('support.name') }{ !name && <b>*</b> }</label>
                        <input
                            type="text"
                            placeholder="John Doe"
                            className="custom_input"
                            value={ name }
                            onChange={ (e) => {
                                setName(e.target.value);
                            } }
                        />
                    </div>
                    <div className="input_container">
                        <label className="input_lable">{ t('support.email') }{ !isValidEmail && <b>*</b> }</label>
                        <input
                            type="text"
                            placeholder="John_doe@gmail.com"
                            className="custom_input"
                            value={ email }
                            onChange={ (e) => {
                                const newEmail = e.target.value;
                                setEmail(newEmail);
                                setIsValidEmail(validateEmail(newEmail));
                            } }
                        />
                    </div>
                    <div className="input_container">
                        <label className="input_lable">{ t('support.issue') }</label>
                        <div className="issue_select_container">
                            <select className='issue_select' value={ issue } onChange={ (e) => setIssue(e.target.value) }>
                                <option>{ t('support.options.feedback') }</option>
                                <option>{ t('support.options.request') }</option>
                                <option>{ t('support.options.bug') }</option>
                                <option>{ t('support.options.others') }</option>
                            </select>
                        </div>

                    </div>
                    <div className="input_container">
                        <label className="input_lable">{ t('support.details') }{ !detail && <b>*</b> }</label>
                        <textarea
                            type="text"
                            placeholder="......"
                            className="details_container"
                            value={ detail }
                            onChange={ (e) => {
                                setDetail(e.target.value);
                            } }
                        />
                    </div>
                    <button className='submit_btn' onClick={ () => onPress() }>{ t('support.submit') }</button>
                </div>
            </div>
        </div>
    )
}