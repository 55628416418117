import axios from "axios";
import { t } from "i18next";
import { clientInfo } from "../../resources";

let showRoot;

export function setRoot(root) {
    showRoot = root;
}

const instance = axios.create({
    baseURL: clientInfo.mDomain,
    timeout: 5000,
    method: 'post'
});

instance.interceptors.request.use((config) => {
    config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...config.headers,
    };

    return config;
});

function apiReq({ action = '', data = {}, headers = {} }) {
    showRoot.setSpinner({ show: true })
    const onSuccess = (response) => {
        return response;
    };
    const onError = (error) => {
        return Promise.reject(error.response.data);
    };

    return async function () {
        try {
            const response = await instance({
                method: 'post',
                data: {
                    action,
                    data,
                },
                headers: {
                    ...headers,
                },
            });

            console.log('==> success: ', response.data);

            showRoot.setSpinner({ show: false })
            return onSuccess(response)
        } catch (error) {
            console.log('==> error: ', error.message);

            if (error.message === 'Network Error') {
                showRoot.setSpinner({ show: true, msg: t('err.timeout') })
            } else {
                showRoot.setSpinner({ show: false })
            }

            setTimeout(() => {
                showRoot.setSpinner({ show: false })
            }, 5000)

            return onError(error)
        }
    };
}

/**
 * @param {object} props
 * @property {string} props.name
 * @property {string} props.email
 * @property {Object} props.contact
 * @property {array} props.product
 * @property {string} props.others
 * @property {string} props.remark 
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function adsForm({ name, email, contact, product, others, boost, remark }, success, error) {
    try {
        const response = await apiReq({
            action: 'ads_form',
            data: {
                name,
                email,
                contact,
                product,
                boost,
                others,
                remark
            },
        })()

        if (success) success(response.data);
        return response.data;
    } catch (e) {
        if (error) error(e);
        return e;
    }
}

/**
 * @param {object} props
 * @property {string} props.name
 * @property {string} props.email
 * @property {string} props.issue
 * @property {string} props.detail 
 * @param {function} [props.success]
 * @param {function} [props.error]
 */
async function supportForm({ name, email, issue, detail }, success, error) {
    console.log(' name, email, issue, detail: ', name, email, issue, detail);
    try {
        const response = await apiReq({
            action: 'support_form',
            data: {
                name,
                email,
                issue,
                detail
            },
        })()

        if (success) success(response.data);
        return response.data;
    } catch (e) {
        if (error) error(e);
        return e;
    }
}

export default Object.freeze({
    adsForm,
    supportForm
});